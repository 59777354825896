import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  Navigate,
  UNSAFE_NavigationContext,
  useNavigate,
} from 'react-router-dom'
import Swal from 'sweetalert2'
import { changeFoundTarjeta, clearTarjetaInfo } from '../../actions/tarjetas'
import checkedIcon from '../../assets/checkedIcon.svg'
import { decryptObject, encrypt } from '../../helpers/crypto'
import { useForm } from '../../hooks/useForm'

export const StrikeReceipt = ({ captcha }) => {
  const [showEmail, setShowEmail] = useState('OK')

  const navigate = useNavigate()

  const dispatch = useDispatch()

  //eslint-disable-next-line
  const {
    priceBTC,
    equivalentBTC,
    commission,
    totalBTC,
    amount,
    transactionId,
    wallet,
    networkComision,
  } = useSelector((state) => state.tarjetas)
  const encryptedKey = encrypt(process.env.REACT_APP_API_KEY)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const useBackListener = (callback) => {
    const navigator = useContext(UNSAFE_NavigationContext).navigator

    useEffect(() => {
      const listener = ({ location, action }) => {
        if (action === 'POP') {
          callback({ location, action })
        }
      }

      const unlisten = navigator.listen(listener)
      return unlisten
    }, [callback, navigator])
  }

  let separator = '/'
  let newDate = new Date()
  let date = newDate.getDate()
  let month = newDate.getMonth() + 1
  let year = newDate.getFullYear()

  const initialForm = {
    email: null,
    email2: null,
    email3: null,
  }

  const [values, handleInputChange, reset] = useForm(initialForm)

  const { email, email2, email3 } = values

  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$')

  const sendEmail = async (email, email2, email3, transactionId) => {
    const url = process.env.REACT_APP_API_URL + '/giftcard/receip/send'
    const token = localStorage.getItem('token') || ''
    const encryptedToken = encrypt(token)

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        key: encryptedKey,
        Authorization: `Bearer ${encryptedToken}`,
      },
    }
    const requestData = {
      email,
      email2,
      email3,
      transactionId,
      type: 'strike',
    }
    
    const res = await axios.post(url, requestData, config)
    res.data.data = decryptObject(res.data.data)
    if (res.data.status && res.data.key === 'receip:sent') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Email enviado',
        iconColor: '#F7931A',
        text: 'Te hemos enviado un email con los datos de la transacción',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#F7931A',
      })
      setShowEmail('NO')
    }
  }

  const submitEmail = (e) => {
    e.preventDefault()
    if (!validEmail.test(email)) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Email invalido',
        iconColor: '#F79327',
        text: 'Por favor ingresa un email valido',
        showConfirmButton: false,
        timer: 4500,
      })
      return
    }
    sendEmail(email, email2, email3, transactionId)
    reset()
  }

  useBackListener(({ location }) => {
    dispatch(changeFoundTarjeta())
    dispatch(clearTarjetaInfo())
    navigate('/', { replace: true })
  })

  const handlerOtherGiftCard = () => {
    dispatch(changeFoundTarjeta())
    dispatch(clearTarjetaInfo())
    navigate('/canjear')
  }

  const goHome = () => {
    dispatch(changeFoundTarjeta())
    dispatch(clearTarjetaInfo())
    navigate('/home')
  }

  if (!captcha) {
    return <Navigate to="/canjear" replace />
  }

  return (
    <Container>
      <Row className="mt-5 mb-5">
        <Col xs={0} sm={0} md={1} lg={2}></Col>
        <Col xs={12} sm={12} md={10} lg={8} style={{ textAlign: 'center' }}>
          <div className="card-transaction card-shadow">
            <Container>
              <Row>
                <Col>
                  <img
                    className="img-card-transaction img-fluid"
                    src={checkedIcon}
                    alt="checked"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1 className="bold mb-3">Transacción realizada</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 className="mb-3">
                    En unos minutos podrás ver el monto reflejado en tu wallet
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    Valor de la tarjeta:
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right' }}
                  >
                    ${amount % 1 === 0 ? amount + '.00' : amount}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    Equivalente en BTC
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right' }}
                  >
                    {Number.parseFloat(equivalentBTC).toFixed(8)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    Comisión de servicio BTC
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right' }}
                  >
                    5% ({Number.parseFloat(commission).toFixed(8)})
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row style={{ backgroundColor: '#ffffff' }}>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    BTC a recibir:
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right', color: '#00953F' }}
                  >
                    {Number.parseFloat(totalBTC).toFixed(8)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    Fecha:
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right' }}
                  >
                    {' '}
                    {`${year}${separator}${
                      month < 10 ? `0${month}` : `${month}`
                    }${separator}${date}`}
                  </p>
                </Col>
                {/* <Col><p className='bold mt-3' style={{textAlign: 'right'}}>25/03/2022 01:47:48 pm</p></Col> */}
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row>
                <Col>
                  <p
                    className="bold mt-3 card-text-left"
                    style={{ textAlign: 'left' }}
                  >
                    Usuario Strike:
                  </p>
                </Col>
                <Col>
                  <p
                    className="bold mt-3 card-text-right"
                    style={{ textAlign: 'right' }}
                  >
                    {wallet}
                  </p>
                </Col>
                {/* <Col><p className='bold mt-3' style={{textAlign: 'right'}}>25/03/2022 01:47:48 pm</p></Col> */}
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              <Row>
                <Col>
                  <p className="bold mt-3" style={{ textAlign: 'center' }}>
                    Identificador de transacción:
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                  <div className="box">
                    <p id="txid" className="bold" style={{ fontSize: '14px' }}>
                      {transactionId}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="linea-separadora-dark"></Col>
              </Row>
              {/* <Row>
                <Col>
                  <h5
                    style={{ textAlign: 'center' }}
                    className="bold mt-3 me-5 ms-5"
                  >
                    Dirección de Wallet
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={0} md={0}></Col>
                <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                  <p className="p-wallet">{wallet}</p>
                </Col>
                <Col xs={0} md={0}></Col>
              </Row> */}
              {showEmail === 'OK' ? (
                <>
                  <Row>
                    <Col xs={0} md={0}></Col>
                    <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                      <h5 className="bold mt-3 mb-3 h5-email-realizado">
                        Ingresa tu email para recibir el detalle de la
                        transacción (Opcional)
                      </h5>
                    </Col>
                    <Col xs={0} md={0}></Col>
                  </Row>

                  <Row>
                    <Col xs={0} md={0}></Col>
                    <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                      <input
                        type="email"
                        name="email"
                        value={email || ''}
                        onChange={handleInputChange}
                        className="field-email mb-2"
                        placeholder="email 1"
                      />
                    </Col>
                    <Col xs={0} md={0}></Col>
                  </Row>

                  {/* <Row>
                    <Col xs={0} md={0}></Col>
                    <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                      <input
                        type="email"
                        name="email2"
                        value={email2 || ''}
                        onChange={handleInputChange}
                        className="field-email mb-2"
                        placeholder="email 2"
                      />
                    </Col>
                    <Col xs={0} md={0}></Col>
                  </Row> */}

                  {/* <Row>
                    <Col xs={0} md={0}></Col>
                    <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                      <input
                        type="email"
                        name="email3"
                        value={email3 || ''}
                        onChange={handleInputChange}
                        className="field-email mb-2"
                        placeholder="email 3"
                      />
                    </Col>
                    <Col xs={0} md={0}></Col>
                  </Row> */}

                  <Row>
                    <Col xs={0} md={0}></Col>
                    <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                      <button
                        style={{ width: '8rem' }}
                        className="btn-canjear"
                        onClick={submitEmail}
                      >
                        Enviar
                      </button>
                    </Col>
                    <Col xs={0} md={0}></Col>
                  </Row>
                </>
              ) : null}
            </Container>
          </div>
        </Col>
        <Col xs={0} sm={0} md={1} lg={2}></Col>
      </Row>

      <Row>
        <Col xs={0} md={2}></Col>
        <Col xs={12} md={4} style={{ textAlign: 'center' }}>
          <button
            className="btn-canjear btn-realizado-canjear"
            onClick={handlerOtherGiftCard}
          >
            Canjear otra tarjeta
          </button>
        </Col>
        <Col xs={12} md={4} style={{ textAlign: 'center' }}>
          <button
            className="btn-canjear btn-realizado-finalizar"
            onClick={goHome}
          >
            Finalizar
          </button>
        </Col>
        <Col xs={0} md={2}></Col>
      </Row>

      <Row>
        <Col xs={0} md={1}></Col>
        <Col xs={12} md={10} style={{ textAlign: 'center' }}>
          <h5 className="bold mt-5 mb-5">
            Para consultas o soporte escríbenos a soporte@ditobanx.com
          </h5>
        </Col>
        <Col xs={0} md={1}></Col>
      </Row>
      {/* <Row>
        <Col xs={0} md={3}></Col>
        <Col xs={12} md={6} style={{textAlign: "center"}}>
            <button 
              style={{width: '16rem'}}
              className='btn-canjear mt-5'
              onClick={handlerOtherGiftCard}
            >
              Canjear otra tarjeta
            </button>
        </Col>
        <Col xs={0} md={3}></Col>
      </Row> */}
    </Container>
  )
}


