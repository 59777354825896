import React, { useEffect } from 'react'
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom'
import { isTokenStillValid, parseJWT } from '../helpers/validateToken'
import Swal from 'sweetalert2'
import '../styles/Selector.css'
import logoMuun from '../assets/wallet1.svg'
import logoSatoshi from '../assets/wallet2.svg'
import logoBlue from '../assets/wallet3.svg'
import logoStrike from '../assets/img/strikelogo.png'

export const WalletSelector = ({ captcha }) => {
  const navigate = useNavigate()
  let token = localStorage.getItem('token')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  if (!captcha) {
    return <Navigate to="/canjear" replace />
  }

  const handleCommonAction = async (e) => {
    e.preventDefault()
    if (token && isTokenStillValid(parseJWT(token))) {
      navigate('/common')
    } else if (!token) {
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: 'Por favor recargue la pagina.',
        icon: 'warning',
        iconColor: '#F79327',
        showCancelButton: false,
        confirmButtonColor: '#F79327',
        confirmButtonText: 'Recargar la pagina',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      })
    } else if (!isTokenStillValid(parseJWT(token))) {
      Swal.fire({
        title: 'Tiempo de sesión expirado',
        text: 'Por favor recargue la pagina.',
        icon: 'warning',
        iconColor: '#F79327',
        showCancelButton: false,
        confirmButtonColor: '#F79327',
        confirmButtonText: 'Recargar la pagina',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      })
    }
  }

  const handleStrikeAction = async (e) => {
    e.preventDefault()
    if (token && isTokenStillValid(parseJWT(token))) {
      navigate('/strike')
    } else if (!token) {
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: 'Por favor recargue la pagina.',
        icon: 'warning',
        iconColor: '#F79327',
        showCancelButton: false,
        confirmButtonColor: '#F79327',
        confirmButtonText: 'Recargar la pagina',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      })
    } else if (!isTokenStillValid(parseJWT(token))) {
      Swal.fire({
        title: 'Tiempo de sesión expirado',
        text: 'Por favor recargue la pagina.',
        icon: 'warning',
        iconColor: '#F79327',
        showCancelButton: false,
        confirmButtonColor: '#F79327',
        confirmButtonText: 'Recargar la pagina',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      })
    }
  }

  let buttons = document.querySelectorAll('.selected')

  buttons.forEach((button) => {
    button.addEventListener('click', (_) => {
      buttons.forEach((button) => {
        button.classList.remove('edit')
      })
      button.classList.toggle('edit')
    })
  })

  return (
    <div className="bg-white">
      <Container>
        <Row>
          <Col xs={0} md={2}></Col>
          <Col xs={12} md={8} style={{ textAlign: 'center' }}>
            <h1 className="bold mt-5 mb-5">
              Selecciona la billetera donde quieres recibir tus
              <img
                src={require('../assets/DitoBanxBitcoinV3.png')}
                alt="btc"
                className="img-bitcoin-title-home"
              />
            </h1>
          </Col>
          <Col xs={0} md={2}></Col>
        </Row>
      </Container>
      <div className="div-bg-light">
        <Container>
          <br className="d-none d-md-block d-lg-block" />
          <br className="d-block d-md-none d-lg-none d-xl-none" />
          <h4
            className="bold d-block d-md-none d-lg-none d-xl-none text-center"
            style={{ fontSize: '19px' }}
          >
            Si tu billetera es una de las siguientes o algúna que soporte
            transferencias <br></br> vía Lightning u On Chain
          </h4>
          <Row className="d-none d-md-block d-lg-block">
            <Col xs={12} md={9} lg={9} style={{ textAlign: 'left' }}>
              <h4 className="bold mt-5 mb-5" style={{ fontSize: '19px' }}>
                Si tu billetera es una de las siguientes o algúna que soporte
                transferencias <br></br> vía Lightning u On Chain
              </h4>
            </Col>
            <Col xs={0} md={3} lg={3}></Col>
          </Row>
          <Row className="mb-5" style={{ textAlign: 'left' }}>
            <Col xs={0} md={7} lg={7}>
              <Row>
                <Col xs={0} md={4} lg={4} className="text-center">
                  <img
                    className="img-fluid img-selector-wallets"
                    src={logoSatoshi}
                    alt="tarjeta"
                  />
                  <p
                    style={{
                      fontWeight: '500',
                      marginTop: '9px',
                      fontSize: '14px',
                    }}
                  >
                    Wallet of Satoshi
                  </p>
                </Col>
                <Col xs={0} md={4} lg={4} className="text-center">
                  <img
                    className="img-fluid img-selector-wallets"
                    src={logoMuun}
                    alt="tarjeta"
                  />
                  <p
                    style={{
                      fontWeight: '500',
                      marginTop: '9px',
                      fontSize: '14px',
                    }}
                  >
                    Muun Wallet
                  </p>
                </Col>
                <Col xs={0} md={4} lg={4} className="text-center">
                  <img
                    className="img-fluid img-selector-wallets"
                    src={logoBlue}
                    alt="tarjeta"
                  />
                  <p
                    style={{
                      fontWeight: '500',
                      marginTop: '9px',
                      fontSize: '14px',
                    }}
                  >
                    Blue Wallet
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={0} md={2} lg={2}></Col>
            <Col xs={0} md={3} lg={3}>
              <button
                style={{ width: '10rem' }}
                className="btn-canjear d-none d-md-block d-lg-block"
                onClick={handleCommonAction}
              >
                Continuar
              </button>
            </Col>
          </Row>
          <br className="d-block d-md-none d-lg-none d-xl-none" />
          <div className="position-relative">
            <div className="btnCentered">
              <button
                style={{ width: '10rem' }}
                className="btn-canjear d-block d-md-none d-lg-none d-xl-none"
                onClick={handleCommonAction}
              >
                Continuar
              </button>
            </div>
            <br className="d-block d-md-none d-lg-none d-xl-none" />
          </div>
          <br className="d-block d-md-none d-lg-none d-xl-none" />
        </Container>
        <br></br>
      </div>
      <div>
        <Container className="mb-5">
          <h4
            className="bold mt-5 mb-5 d-block d-md-none d-lg-none d-xl-none text-center"
            style={{ fontSize: '19px' }}
          >
            Si tu billetera es Strike
          </h4>
          <Row className="d-none d-md-block d-lg-block">
            <Col xs={12} md={9} lg={9} style={{ textAlign: 'left' }}>
              <h4 className="bold mt-5 mb-5" style={{ fontSize: '19px' }}>
                Si tu billetera es Strike
              </h4>
            </Col>
            <Col xs={0} md={3} lg={3}></Col>
          </Row>
          <Row style={{ textAlign: 'left' }}>
            <Col xs={0} md={7} lg={7}>
              <Row>
                <Col xs={0} md={4} lg={4} className="text-center">
                  <img
                    className="img-fluid img-selector-wallets"
                    src={logoStrike}
                    alt="tarjeta"
                  />
                  <p
                    style={{
                      fontWeight: '500',
                      marginTop: '9px',
                      fontSize: '14px',
                    }}
                  >
                    Strike
                  </p>
                </Col>
                <Col xs={0} md={4} lg={4}></Col>
                <Col xs={0} md={4} lg={4}></Col>
              </Row>
            </Col>
            <Col xs={0} md={2} lg={2}></Col>
            <Col xs={0} md={3} lg={3}>
              <button
                style={{ width: '10rem' }}
                className="btn-canjear d-none d-md-block d-lg-block"
                onClick={handleStrikeAction}
              >
                Continuar
              </button>
            </Col>
          </Row>
          <br className="d-block d-md-none d-lg-none d-xl-none" />
          <div className="position-relative d-md-none d-lg-none d-xl-none">
            <div className="btnCentered d-md-none d-lg-none d-xl-none">
              <button
                style={{ width: '10rem' }}
                className="btn-canjear d-block d-md-none d-lg-none d-xl-none"
                onClick={handleStrikeAction}
              >
                Continuar
              </button>
            </div>
            <br className="d-block d-md-none d-lg-none d-xl-none" />
          </div>
        </Container>
      </div>
    </div>
  )
}
