import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from '../hooks/useForm';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { clearTarjetaInfo, startGetTarjetaValues } from '../actions/tarjetas';
import { resetCaptchaInfo, startValidateCaptcha } from '../actions/auth';
import { isTokenStillValid, parseJWT } from '../helpers/validateToken';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import { TimerRateLimit } from './TimeRateLimit';
import instrucciones from '../assets/InstruccionesTarjeta.svg';


export const Canjear = () => {

  const [loading, setLoading] = useState(false);

  const { rateLimit } = useSelector(state => state.auth);

  const store = useStore();
  
  const captchaRef = useRef();
  const btnRef = useRef();

  const dispatch = useDispatch();

  const navigate = useNavigate();
    

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  useEffect(() => {
    dispatch(clearTarjetaInfo());
    dispatch(resetCaptchaInfo());
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) {
        setLoading(false);
      }
    }, 5000);
      
    return () => clearTimeout(timer);
  }, [loading])
  

  const initialForm = {
    code: '',
    publicKey: ''
  }

  const [values, handleInputChange, reset] = useForm(initialForm);

  const { code, publicKey } = values;
  

  let token = localStorage.getItem('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (code === '' || publicKey === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Campos vacios',
        text: 'Por favor ingrese los datos requeridos',
        showConfirmButton: false,
      })
      setLoading(false);
      return;
    }
    
    if (captchaRef.current.getValue()) {

      if (!token) {
        Swal.fire({
          title: 'Ha ocurrido un error',
          text: "Por favor recargue la pagina.",
          icon: 'warning',
          iconColor: '#F79327',
          showCancelButton: false,
          confirmButtonColor: '#F79327',
          confirmButtonText: 'Recargar la pagina'
          }).then((result) => {
          if (result.isConfirmed) {
              window.location.reload();
          }
        })
        return;
      }
      if (token && isTokenStillValid(parseJWT(token))) {

        await dispatch(startGetTarjetaValues(publicKey.toUpperCase(), code));
        store.getState().tarjetas.tarjetaFound
          ? navigate('/selector', { replace: true })
          : setLoading(false)

      } else if (!isTokenStillValid(parseJWT(token))) {
        Swal.fire({
          title: 'Tiempo de sesión expirado',
          text: "Por favor recargue la pagina.",
          icon: 'warning',
          iconColor: '#F79327',
          showCancelButton: false,
          confirmButtonColor: '#F79327',
          confirmButtonText: 'Recargar la pagina'
          }).then((result) => {
          if (result.isConfirmed) {
              window.location.reload();
          }
        })
      }
      
      
    } else {

      Swal.fire({
        title: 'Por favor verifica el captcha',
        text: 'Por favor completa el reCAPTCHA',
        icon: 'warning',
        iconColor: '#F79327',
        showConfirmButton: false,
      })

    }
    reset();
    setLoading(false);
  }

  const onChange = (e) => {
    dispatch(startValidateCaptcha(captchaRef.current.getValue()));
  }

  const formatChars = {
    '*' : '[A-Za-z0-9]',
    // '-' : '[-]' // Para que no sea necesario que el usuario escriba el guion hay que comentarlo
  }


  return (
    <>
      <form onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col xs={0} md={2}></Col>
            <Col xs={12} md={8} style={{ textAlign: 'center' }}>
              <h1 className="bold mt-5 mb-5">
                Ingresa los códigos secretos que encontraras en tu tarjeta
              </h1>
            </Col>
            <Col xs={0} md={2}></Col>
          </Row>
        </Container>
        <div>
          <Row
            className="div-bg-light"
            style={{ marginLeft: '0', marginRight: '0' }}
          >
            <Col xs={0} md={2}></Col>
            <Col
              xs={11}
              md={8}
              className="mt-5 mb-5"
              style={{
                textAlign: 'center',
                paddingLeft: '0',
                paddingRight: '0',
                zIndex: 10,
              }}
            >
              <img
                className="img-tarjeta-instructions img-fluid"
                src={instrucciones}
                alt="instrucciones"
              />
            </Col>    
              <Col
                xs={1}
                md={2}
                className='col-img-bitcoinsign-instrucciones'
              >
                <img
                  className="img-bitcoinsign-instrucciones"
                  src={require('../assets/DitoBanxBitcoinSign.png')}
                  alt="tarjeta"
                />
              </Col>  
          </Row>
        </div>
        <div className="div-bg-dark">
          <Row style={{ marginLeft: '0', marginRight: '0' }}>
            <Col xs={0} md={3} sm={2} lg={3}></Col>
            <Col
              xs={12}
              md={6}
              sm={8} 
              lg={6}
              className="mt-5"
              style={{
                textAlign: 'center',
                paddingLeft: '0',
                paddingRight: '0',
                zIndex: 10,
              }}
            >
              <div className="card-form">
                <div className="label-form">
                  <label htmlFor="publicKey">*Código 01</label>
                </div>
                <InputMask
                  formatChars={formatChars}
                  mask="****-********-******"
                  maskChar={''}
                  type="text"
                  name="publicKey"
                  value={publicKey}
                  onChange={handleInputChange}
                  className="field-form"
                  placeholder="8374-94048474-899404"
                  pattern='[a-zA-Z0-9]{4}-[a-zA-Z0-9]{8}-[a-zA-Z0-9]{6}'
                  alwaysShowMask={false}

                ></InputMask>
                <div className="label-form">
                  <label htmlFor="code">*Código 02</label>
                </div>
                <InputMask
                  mask="******"
                  maskChar={''}
                  type="text"
                  name="code"
                  value={code}
                  onChange={handleInputChange}
                  className="field-form"
                  placeholder="837494"
                  pattern='[a-zA-Z0-9]{6}'
                ></InputMask>
              </div>
            </Col>
            <Col xs={0} md={3} sm={2} lg={3}></Col>
          </Row>
          
            <Row style={{ marginLeft: '0', marginRight: '0' }}>
              <Col xs={0} md={3} sm={2} lg={3}></Col>
              <Col xs={12} md={6} sm={8} lg={6}>
                <p className="p-form mb-5">*Requerido</p>
              </Col>
              <Col xs={0} md={3} sm={2} lg={3}></Col>
            </Row>
          
        </div>

        <Container>
          <Row className="mt-3">
            <Col xs={0} md={3}></Col>
            <Col xs={12} md={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              {
                loading 
                  ?
                    null
                  :
              rateLimit ? (
                <TimerRateLimit />
              ) : (
                <ReCAPTCHA
                  style={{ margin: 'auto' }}
                  className="mt-5 recaptcha mb-5"
                  ref={captchaRef}
                  sitekey={process.env.REACT_APP_SITE_KEY_RECAPTCHA}
                  onChange={onChange}
                />
              )}
            </Col>
            <Col xs={0} md={3}></Col>
          </Row>

          <Row>
            <Col xs={0} md={4}></Col>
            <Col xs={12} md={4} style={{ textAlign: 'center' }}>
              {
                loading 
                  ?
                    <Spinner animation="border" variant="dark" className='spinner-form' />
                  :
                    
              rateLimit ? (
                null
              ) : (
                <button className="btn-canjear" type="submit" ref={btnRef}>
                  Continuar
                </button>
              )}
                  
            </Col>
            <Col xs={0} md={4}></Col>
          </Row>
          <Row>
            <Col xs={0} md={0}></Col>
            <Col xs={12} md={12} style={{ textAlign: 'center' }}>
              <h3 className="bold mt-5 mb-5 h2-soporte">
                Si tuviste un inconveniente puedes contactarnos a<br />
                soporte@ditobanx.com
              </h3>
            </Col>
            <Col xs={0} md={0}></Col>
          </Row>
        </Container>
      </form>
    </>
  );
}
